.kpis-accordion {
  margin: 0 0.5rem;
  & .container {
    border-radius: 8px !important;
    box-shadow: none !important;
    margin:16px 0;

  }
  & .container::before {
    opacity: 0 !important;
  }
  & .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    & p,
    & span {
      text-transform: uppercase;
    }
    & p {
      font-weight: bold;
    }
    & .MuiChip-root {
      border-radius: 5px;
    }
  }
  & .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    & > div {
      width: 40%;
      margin: 0.5rem;
      &:nth-child(even) {
        border-left: 1px solid grey;
        padding-left: 0.5rem;
      }
      & p.label {
        font-size: 0.7rem;
        color: grey;
      }
      & p.value {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
}
