$TITLE_HEIGHT: 28px;

.collection {
  border: none;
}

.title span,
.title-2-rows span {
  font-weight: 600;
  font-size: 1.3em;
}

.MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
  width: 100%;
}

.MuiCardContent-root {
  padding: 8px 16px 0 !important;
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected {
  .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1 {
    font-weight: bold;
  }
}

ul.collection {
  margin: 0;
}

div[class^='swipeable-list'] {
  background: transparent;
}

div[class^='swipeable-list-item__content'] {
  background: transparent;
}

div[class^='swipeable-list-item'] {
  margin-bottom: 5px;
  border-radius: 5px;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}
