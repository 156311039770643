.stepper-promotion{
    display: flex;
    max-height: 60px;
    &__content > span{
        background-color: #90b8b3 !important;
        width: 100% !important;
        >span{
            background-color: #005442 !important;
        }
    }
}
.stepper-title{
    background-color: #ffffff;
    padding: 16px 16px 7px 16px;
    &__text{
        color: rgba(0, 0, 0, 0.54)
    }
}
