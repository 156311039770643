html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #eee;

  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-weight: unset;
  color: rgba(0, 0, 0, 0.87);

  body {
    padding: 0;
    margin: 0 auto;
    display: contents;
    overscroll-behavior-y: contain;

    #root {
      height: 100%;

      & > .content {
        background-color: transparent !important;
      }
    }
  }
}
