
.comission-detail-content{
    background-color: rgba(144, 184, 179,0.38);
}
.comission-detail-content__text{
    text-align: justify;
    padding:0px 16px 16px 16px;
}
.comission-detail-item{
    display:flex;
    width:auto;
    justify-content:space-between;
    padding:16px 16px 16px 16px;
    &__child{
        padding: 0px;
        flex-direction:column
    }
    &__text{
        text-align: end;
    }
}

.comission-biddingText{
        margin:0px;
        color:#005442;
        text-align: left;
}