form.properties-filter-form {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    &>div {
        width: 100%;
        margin: .5rem 0 !important;
    }

    & div.MuiFormControl-root label {
        left: 1rem !important;
    }

    & label.MuiInputLabel-outlined {
        transform: translate(0px, 20px) scale(1);

        &.MuiInputLabel-shrink {
            transform: translate(0px, -6px) scale(0.75);
        }
    }

    & div.checkbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}