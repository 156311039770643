.step-wizard{
        background: #ffffff;
        min-height: calc(100% - 64px);
        width: 100%;
        & .itemBox {
            width: 100%;
        }
        & .noInformed{
            font-size: 1rem;
            font-weight: 400;
            font-style: italic;
        }
        & .picture-data-summary-images{  
                    margin-top: 10px !important;
                    margin-bottom: 10px !important;        
            
        }
}