%header-image {
  box-shadow: none;
  background-size: cover;
  background-color: transparent !important;
  background-image: url('/header-detail.jpg');
  position: relative;

  &::after {
    @extend %gradient-overlay;
  }
}

%gradient-overlay {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.21) 0%,
    #559090 100%
  );
}

.background-transparent {
  height: 200px;
  @extend %header-image;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  background: #005442;

  .menu-button:focus {
    background: transparent;
  }
}

.drawer {
  .MuiTypography-body1,
  .MuiListItem-root {
    font-size: 0.9rem;
  }

  &-header {
    position: relative;
    width: 100%;

    &-image {
      height: 165px;
      @extend %header-image;
    }

    .MuiTypography-root {
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      font-size: 1.4rem;
      text-transform: capitalize;
      color: white;
    }
  }

  .MuiListItem-button:not(.Mui-selected) {
    svg {
      fill: #90b8b3;
    }

    .MuiTypography-root {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .MuiListItem-button.Mui-selected,
  .MuiListItem-button:hover {
    background: transparent;

    svg {
      fill: #006666;
    }

    .MuiTypography-root {
      color: #005442;
    }

    .MuiTouchRipple-root {
      background: rgba(0, 102, 102, 0.12);
    }
  }

  .MuiTouchRipple-root {
    margin: 0 0.5rem;
    border-radius: 4px;
  }
}
